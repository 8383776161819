import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'

import { AdminLayout } from 'components/Layout'
import PageLoading from 'components/PageLoading'

import SalesFilter from './components/SalesFilter'
import StatsByPeriodTable from './components/StatsByPeriodTable'
import OverallInsightsTable from './components/OverallInsightsTable'
import {
  getSalesPerformances,
  setPage,
  setTotal,
  setPageSize,
  setFilterForm,
  setData,
} from 'redux/sales'

import type { TFilterForm } from './helpers/constant'
import moment from 'moment'
import { formatOption } from './helpers/constant'

const SALESFILTERS = 'sales_filters'

export default function SalesPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { filterForm } = useAppSelector(state => state.sales)
  const [loading, setLoading] = useState(false)

  const handleFilterChange = (type: string, value: any) => {
    let newParams = {
      ...filterForm,
    }
    if (type === 'sort') {
      newParams = {
        ...filterForm,
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
    } else {
      newParams = {
        ...filterForm,
        [type]: value,
      }
    }
    getTable(newParams, 1)
  }

  const handlePageChange = (newPage: number) => {
    getTable(filterForm, newPage)
  }

  const getTable = async (form: TFilterForm, page: number) => {
    try {
      setLoading(true)
      dispatch(setPage(page))
      dispatch(setFilterForm(form))

      const res = await getSalesPerformances({
        ...form,
        option: formatOption(form.unit, moment(form.option)),
        page,
        ignoreCache: false,
      })
      setLoading(false)
      if (res) {
        dispatch(setTotal(res.total))
        dispatch(setPageSize(res.per_page))
        dispatch(setData(res.data))
        localStorage.setItem(
          SALESFILTERS,
          JSON.stringify({
            form,
            page,
          }),
        )
      }
    } catch (err: any) {
      setLoading(false)
      if (err && err.message) {
        toast.error(err.message)
      }
    }
  }
  const goDetail = (item: any) => {
    navigate(
      `/sales/detail/${item.closer_id}?unit=${
        filterForm.unit
      }&option=${formatOption(filterForm.unit, moment(filterForm.option))}`,
    )
  }

  useEffect(() => {
    const localFilterParams = JSON.parse(
      localStorage.getItem(SALESFILTERS) || 'false',
    )
    if (localFilterParams) {
      dispatch(setFilterForm(localFilterParams?.form))
      dispatch(setPage(localFilterParams?.page))
      getTable(localFilterParams?.form, localFilterParams?.page)
    } else {
      getTable(filterForm, 1)
    }
  }, [])

  return (
    <AdminLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className='px-10 py-8'>
          <SalesFilter handleFilterChange={handleFilterChange} />
          <div className='mt-8'>
            {filterForm.model === 'stats_by_period' && (
              <StatsByPeriodTable
                onPageNoChange={handlePageChange}
                handleFilterChange={handleFilterChange}
                goDetail={goDetail}
              />
            )}
            {filterForm.model === 'overall_insights' && (
              <OverallInsightsTable
                onPageNoChange={handlePageChange}
                handleFilterChange={handleFilterChange}
                goDetail={goDetail}
              />
            )}
          </div>
        </div>
      )}
    </AdminLayout>
  )
}
