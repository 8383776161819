import React from 'react'
import {
  SolidStore,
  SolidMapPin,
  SolidUser,
  SolidCardId,
  SolidCardPayment,
  FaviconColor,
} from '@rushable/icons'
import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import DateAndTime from 'components/DateAndTime'
import PaymentInfo from 'components/PaymentInfo'

import { statusOptions, TFilterForm } from '../helpers/constant'
import { formatCurrency } from 'utils/tools'

export type TDisputeTableProps = {
  filterForm: TFilterForm
  handleFormChange: (type: string, value: any) => void
  loading?: boolean
  total: number
  current: number
  pageSize: number
  data: any[]
  onPageNoChange: (page: number) => void
  setOpen: (open: boolean) => void
  setSelectItem: (item: any) => void
}

export default function DisputeTable({
  filterForm,
  handleFormChange,
  loading,
  total,
  current,
  pageSize,
  data,
  onPageNoChange,
  setOpen,
  setSelectItem,
}: TDisputeTableProps) {
  const { sort, sort_by } = filterForm
  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      custom: (value: string) => {
        let color: any = ''
        let text = statusOptions.filter(v => v.value === value)[0]?.label || ''
        switch (value) {
          case 'needs_response':
            color = 'yellow'
            break
          case 'warning_needs_response':
            color = 'yellow'
            text = 'Needs Response'
            break
          case 'under_review':
            color = 'blue'
            break
          case 'warning_under_review':
            color = 'blue'
            text = 'Under Review'
            break
          case 'won':
            color = 'green'
            break
          case 'charge_refunded':
            color = 'orange'
            break
          case 'lost':
            color = 'red'
            break
          case 'warning_closed':
            color = 'default'
            text = 'Warning Closed'
            break
        }
        return <BadgeText value={text} color={color} />
      },
    },
    {
      key: 'order_id',
      name: 'ORDER ID',
      className: 'w-112',
      custom: (value: string | number) => {
        return <div>{value ? `#${value}` : '-'}</div>
      },
    },
    {
      key: 'amount',
      name: 'TOTAL',
      align: 'right',
      className: 'w-88',
      sortable: true,
      sortDirection: sort_by === 'total' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'total',
          value,
        })
      },
      custom: (value: string) => {
        return <div>${formatCurrency(value)}</div>
      },
    },
    {
      key: 'reason',
      name: 'REASON',
    },
    {
      key: 'location',
      name: 'BRAND',
      custom: (value: any, row: any) => {
        return (
          <div className='flex items-center'>
            <SolidStore size={16} className='mr-1 text-light-100' />
            <div className='text-ellipsis'>
              {value?.brand?.name || row?.order?.location?.brand?.name}
            </div>
          </div>
        )
      },
    },
    {
      key: 'location',
      name: 'LOCATION',
      custom: (value: any, row: any) => {
        return (
          <div className='flex items-center'>
            <SolidMapPin size={16} className='mr-1 text-light-100' />
            <div className='text-ellipsis'>
              {value?.name || row?.order?.location?.name}
            </div>
          </div>
        )
      },
    },
    {
      key: 'order',
      name: 'CUSTOMER',
      className: 'w-160',
      custom: (value: any, row: any) => {
        const { customer } = value || {}
        return (
          <div className='flex items-center'>
            {row.type === 'restaurant' ? (
              <>
                <FaviconColor
                  style={{ width: '16px', mixBlendMode: 'difference' }}
                  className='mr-1'
                />
                <div>Restaurant</div>
              </>
            ) : (
              <>
                <SolidUser size={16} className='mr-1 text-light-100' />
                <div className='text-ellipsis'>
                  {customer?.first_name} {customer?.last_name}
                </div>
              </>
            )}
          </div>
        )
      },
    },
    {
      key: 'order',
      name: 'PAYMENT',
      className: 'w-32',
      custom: (value: any) => {
        const { payment_card_brand, payment_card_last_4 } = value || {}
        return (
          <PaymentInfo
            brand={payment_card_brand || 'unknown'}
            last4={payment_card_last_4}
          />
        )
      },
    },
    {
      key: 'order',
      name: 'BLOCK',
      className: 'w-96',
      custom: (order: any, row: any) => {
        if (row.type === 'restaurant') {
          return null
        } else {
          const { payment_is_blocked, customer } = order
          const { status } = customer
          return (
            <div className='flex items-center'>
              <SolidCardId
                size={20}
                className={status === 'active' ? 'text-dark-100' : 'text-red'}
              />
              <SolidCardPayment
                size={20}
                className={`ml-2 ${
                  payment_is_blocked === 1 ? 'text-red' : 'text-dark-100'
                }`}
              />
            </div>
          )
        }
      },
    },
    {
      key: 'due',
      name: 'DUE',
      align: 'right',
      className: 'w-96',
      sortable: true,
      sortDirection: sort_by === 'due' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'due',
          value,
        })
      },
      custom: (value: string) => {
        return (
          <div className='flex justify-end'>{value ? `${value} day` : '-'}</div>
        )
      },
    },
    {
      key: 'disputed_at',
      name: 'DISPUTE',
      align: 'right',
      className: 'w-96',
      sortable: true,
      sortDirection: sort_by === 'dispute' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFormChange('sort', {
          type: 'dispute',
          value,
        })
      },
      custom: (value: string, item: any) => {
        return (
          <div className='flex justify-end'>
            <DateAndTime
              className='text-light-500'
              format='MMM DD'
              parse='YYYY-MM-DD HH:mm:ss'
              value={value}
            />
          </div>
        )
      },
    },
  ]
  const clickedRow = (item: any) => {
    setOpen(true)
    setSelectItem(item)
  }
  return (
    <div>
      <Table
        className='mb-20'
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={clickedRow}
      />
    </div>
  )
}
