import React from 'react'
import cn from 'classnames'
import { Check } from '@rushable/icons'

export type TStatus =
  | 'draft'
  | 'need_approval'
  | 'ready'
  | 'signing_up'
  | 'onboarding'
  | 'live'
  | 'cancelling'
  | 'canceled'
  | 'withdrawn'

export type TPopArr = {
  checked: number | string | boolean
  text: string
  checkedColor: string
  subText?: string
  subTextColor?: string
}

export type TStageProgressTooltipProps = {
  className?: string
  status: TStatus
  open: boolean
  signUpArr: TPopArr[]
  onboardArr: TPopArr[]
}

export default function StageProgressTooltip({
  className,
  status,
  open,
  signUpArr,
  onboardArr,
}: TStageProgressTooltipProps): JSX.Element {
  const render_arr =
    status === 'draft' ||
    status === 'need_approval' ||
    status === 'ready' ||
    status === 'signing_up'
      ? signUpArr
      : onboardArr
  return (
    <div
      className={cn(
        'w-[331px] p-6 pb-4 border border-dark-100 rounded-lg bg-dark-500 ',
        className,
      )}
      style={{
        filter: 'drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.24))',
        visibility: open ? 'visible' : 'hidden',
      }}
    >
      {render_arr.map((item, key) => {
        return (
          <div className='flex justify-between pb-2' key={key}>
            <div className='inline-flex items-center h-4'>
              <span
                className={cn(
                  'text-xs',
                  item.checked
                    ? item.checkedColor
                      ? `text-${item.checkedColor}`
                      : 'text-light-700'
                    : 'text-light-300',
                )}
              >
                {item.text}
              </span>

              {item.subText ? (
                <span
                  className={
                    'items-center text-base border border-dark-100 rounded-sm h-4 py-0 px-2 ml-2 ' +
                    (item.subTextColor
                      ? `text-${item.subTextColor}`
                      : 'text-light-700')
                  }
                >
                  {item.subText}
                </span>
              ) : null}
            </div>
            <Check
              size={16}
              className={`relative top-[2px] ${
                item.checked
                  ? item.checkedColor
                    ? `text-${item.checkedColor}`
                    : 'text-green'
                  : 'text-light-100'
              }`}
            />
          </div>
        )
      })}
    </div>
  )
}
