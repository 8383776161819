import { useState, useEffect } from 'react'
import { Loading } from '@rushable/icons'
import { toast } from 'react-toastify'
import { AdminLayout } from 'components/Layout'
import PageLoading from 'components/PageLoading'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import AccountsFilter from './components/AccountsFilter'
import AccountsTable from './components/AccountsTable'
import AccountsFilterModal from './components/AccountsFilterModal'

import {
  getAccounts,
  getOnboardingCount,
  setOnlySelf,
  setMode,
  setFilterParams,
  setPage,
  setPageSize,
  setTotal,
  setData,
  setCounts,
  setOrder,
  setStartState,
  setNeedReload,
} from 'redux/account'

import type { TMode } from 'components/StageSelect'
import type { TFilterParams } from 'pages/Accounts/helpers/constant'

import type { TOrder } from './helpers/constant'

const defaultFilterParams: TFilterParams = {
  page: 1,
  brand_name: '',
  stage: '',
  team_id: '',
  closer_id: '',
  issue: '',
  gbp: '',
  feature: '',
  assignee_id: '',
}

export default function AccountsPage() {
  const dispatch = useAppDispatch()
  // filter
  const { onlySelf, mode, filterParams, page, sortData, needReload } =
    useAppSelector(state => state.account)
  const { authMap } = useAppSelector(state => state.auth)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const getCount = async () => {
    try {
      const res = await getOnboardingCount()
      if (res) {
        dispatch(setCounts(res))
      }
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
  }

  const formatData = (item: any, index: number) => {
    const {
      signup_form,
      onboarding,
      location,
      price,
      convenience_fee,
      last_4,
      brand,
      exp,
      stage_days,
      stage,
      has_attention,
      GBP_need_claim,
      rushable_followup_time,
      cont,
      comparison,
      sales_total,
      GBP_website_is_on,
      GBP_ordering_tracking_is_on,
      success_followup,
      paper_plane,
    } = item
    return {
      id: item.id,
      index,
      onboarding,
      signup_form,
      brand: location
        ? location?.brand?.name || '-'
        : signup_form?.brand_name || '-',
      location: location ? location?.name : signup_form?.location_name || '-',
      pricing: {
        rushable_plan_id: signup_form?.rushable_plan_id || null,
        price,
        convenience_fee,
      },
      team: onboarding?.crm_closer?.crm_team?.name || '-',
      source: signup_form?.source || '',
      closer: {
        avatar: onboarding?.crm_closer?.profile_photo,
        name: `${onboarding?.crm_closer?.user?.first_name} ${onboarding?.crm_closer?.user?.last_name}`,
      },
      assignee: {
        avatar: onboarding?.project_lead?.profile_photo || '',
        name: `${onboarding?.project_lead?.first_name || '-'} ${
          onboarding?.project_lead?.last_name || ''
        }`,
      },
      issue: {
        handRaise: has_attention,
        google: GBP_need_claim,
      },
      payment: { brand, last_4, exp },
      stage_days,
      stage,
      onboarding_followup: {
        time: rushable_followup_time,
        timezone: location?.address?.timezone || '',
      },
      success_followup: {
        time: success_followup,
        timezone: location?.address?.timezone || '',
      },
      features: {
        pickup: !!location?.online_order_config?.order_pickup_config?.enabled,
        delivery:
          !!location?.online_order_config?.order_doordash_delivery_config
            ?.enabled ||
          !!location?.online_order_config?.order_in_house_config?.enabled,
        dineIn:
          !!location?.online_order_config?.order_contactless_dinein_config
            ?.enabled,
        email: !!paper_plane,
        phone: !!location?.location_business_setting?.marketing_phone_number,
      },
      gbp: {
        websiteLink: !!GBP_website_is_on,
        orderingLink: !!GBP_ordering_tracking_is_on,
      },
      week_sales: sales_total,
      comparison: [
        comparison?.['1'] || 0,
        comparison?.['2'] || 0,
        comparison?.['3'] || 0,
        comparison?.['4'] || 0,
      ],
      cont,
    }
  }

  const getLists = async (
    nowParams: { [name: string]: any },
    newSortData?: TOrder,
  ) => {
    const paramsOrder = newSortData ? newSortData : sortData
    const params: any = {
      stage: mode,
      only_self: onlySelf,
      page,
      ...nowParams,
      sort_by: paramsOrder.sort ? paramsOrder.sort_by : '',
      sort: paramsOrder.sort,
    }
    if (nowParams.page && page !== nowParams.page) {
      dispatch(setPage(nowParams.page as number))
    }
    setLoading(true)
    try {
      const res = await getAccounts(params, needReload)
      dispatch(setNeedReload(false))
      dispatch(setTotal(res.total))
      dispatch(setPageSize(res.per_page))
      dispatch(setData(res.data.map(formatData)))
      setLoading(false)
      localStorage.setItem(
        'accounts_filters',
        JSON.stringify({
          ...params,
        }),
      )
    } catch (e: any) {
      setLoading(false)
    }
  }

  const handleModeChange = (newMode: TMode) => {
    dispatch(setMode(newMode))
    dispatch(setFilterParams({ ...defaultFilterParams, stage: newMode }))
    getLists({
      ...defaultFilterParams,
      stage: newMode,
    })
  }

  const handlePageChange = (newPage: number) => {
    getLists({
      ...filterParams,
      page: newPage,
    })
  }

  const handleOnlyChange = (checked: boolean) => {
    const only_self = checked ? '1' : '0'
    dispatch(setOnlySelf(only_self))

    getLists({
      ...filterParams,
      only_self,
    })
  }

  const handleFilterConfirm = (params: TFilterParams) => {
    dispatch(setFilterParams(params))
    dispatch(setMode(params.stage))
    dispatch(setOnlySelf('0'))
    getLists({
      ...params,
      only_self: '0',
    })
  }

  const handleOrderChange = (newSortData: TOrder) => {
    dispatch(setOrder(newSortData))
    getLists(
      {
        ...filterParams,
        stage: mode,
        page: 1,
      },
      newSortData,
    )
  }

  const clearFilter = () => {
    dispatch(
      setFilterParams({
        ...filterParams,
        ...defaultFilterParams,
      }),
    )
    dispatch(setMode('in_sales'))

    getLists({
      ...defaultFilterParams,
      stage: 'in_sales',
    })
  }

  useEffect(() => {
    const localFilterParams = JSON.parse(
      localStorage.getItem('accounts_filters') || 'false',
    )
    const roleId = authMap?.roleId
    if (roleId && !localFilterParams) {
      if (roleId >= 21 && roleId <= 29) {
        dispatch(setMode('onboarding'))
        getLists({
          ...filterParams,
          stage: 'onboarding',
        })
      } else {
        getLists({
          ...filterParams,
        })
      }
    }
  }, [authMap?.roleId])

  useEffect(() => {
    getCount()
    const localFilterParams = JSON.parse(
      localStorage.getItem('accounts_filters') || 'false',
    )
    if (localFilterParams) {
      dispatch(setStartState(localFilterParams))
      getLists({
        ...localFilterParams,
      })
    }
  }, [])

  return (
    <AdminLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className='px-10 py-8'>
          <AccountsFilter
            setOpen={setOpen}
            handleModeChange={handleModeChange}
            handleOnlyChange={handleOnlyChange}
            clearFilter={clearFilter}
          />
          <div className='mt-8'>
            <AccountsTable
              handleOrderChange={handleOrderChange}
              onPageNoChange={value => handlePageChange(value)}
            />
          </div>
          <AccountsFilterModal
            open={open}
            toggle={() => setOpen(!open)}
            onConfirm={handleFilterConfirm}
          />
        </div>
      )}
    </AdminLayout>
  )
}
