import React from 'react'

import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import DateAndTime from 'components/DateAndTime'
import PersonName from 'components/PersonName'
import Switch from 'components/Switch'
import moment from 'moment-timezone'

type TRowProp = {
  [index: string]: any
}

export type TDomainsTableProp = {
  className?: string
  data: Array<TRowProp>
  total: number
  loading?: boolean
  changeId?: number
  current: number
  pageSize: number
  sortData: {
    sort_by: string
    sort: string
  }
  handleFilterChange: (type: string, value: any) => void
  onPageNoChange: (page: number) => void
  changeStatus: (value: boolean, row: any) => void
}

export default function DomainsTable({
  className = '',
  changeId,
  loading,
  data,
  total,
  current,
  pageSize,
  sortData,
  handleFilterChange,
  onPageNoChange,
  changeStatus,
}: TDomainsTableProp): JSX.Element {
  const { sort_by, sort } = sortData
  const columns = [
    {
      key: 'domain',
      name: 'BRAND',
      className: 'w-48',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.brand?.name || '-'}</div>
      },
    },
    {
      key: 'domain',
      name: 'SUBSCRIPTION',
      className: 'w-40',
      sortable: true,
      sortDirection: sort_by === 'status' ? sort : '',

      custom: (value: any) => {
        let color: any = value?.brand?.status === 'inactive' ? 'red' : 'green'
        let newValue =
          value?.brand.status === 'inactive'
            ? 'Cancelled'
            : value?.brand.live_location_count !==
              value?.brand.all_location_count
            ? `${value?.brand.live_location_count}/${value?.brand.all_location_count} Live`
            : 'Live'
        return <>{value ? <BadgeText value={newValue} color={color} /> : '-'}</>
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'status',
          value,
        })
      },
    },
    {
      key: 'domain',
      name: 'DOMAIN',
      custom: (value: any) => {
        return <div className='text-ellipsis'>{value?.hostname || '-'}</div>
      },
    },
    {
      key: 'domain',
      name: 'PRIMARY',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'is_default' ? sort : '',

      custom: (value: any) => {
        return (
          <div className={`${!value?.is_default && 'text-red'}`}>
            {!!value?.is_default ? 'Yes' : 'No'}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'is_default',
          value,
        })
      },
    },
    {
      key: 'crm_user',
      name: 'PURCHASED BY',
      className: 'w-48',
      sortable: true,
      sortDirection: sort_by === 'purchased_by' ? sort : '',

      custom: (value: any) => {
        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={value?.profile_photo}
              name={`${value?.user?.first_name} ${value?.user?.last_name}`}
            />
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'purchased_by',
          value,
        })
      },
    },
    {
      key: 'registered_at',
      name: 'REGISTERED',
      sortable: true,
      align: 'right',
      className: 'w-32',
      sortDirection: sort_by === 'registered_at' ? sort : '',

      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value ? (
              <DateAndTime
                className='text-right text-light-500'
                value={value}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
                noExpired={true}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'registered_at',
          value,
        })
      },
    },
    {
      key: 'renewed_at',
      name: 'RENEWAL',
      sortable: true,
      align: 'right',
      className: 'w-32',
      sortDirection: sort_by === 'renewed_at' ? sort : '',

      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value ? (
              <DateAndTime
                className='text-right text-light-500'
                value={value}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
                noExpired={true}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'renewed_at',
          value,
        })
      },
    },
    {
      key: 'expired_at',
      name: 'EXPIRE',
      sortable: true,
      align: 'right',
      className: 'w-32',
      sortDirection: sort_by === 'expired_at' ? sort : '',
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            {value ? (
              <DateAndTime
                diffThirty={true}
                className='text-right'
                value={value}
                parse='YYYY-MM-DD HH:mm:ss'
                format='MMM DD, YYYY'
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'expired_at',
          value,
        })
      },
    },
    {
      key: 'auto_renew',
      name: 'RENEWAL',
      sortable: true,
      align: 'right',
      className: 'w-32',
      sortDirection: sort_by === 'auto_renew' ? sort : '',

      custom: (value: any, row: any) => {
        return (
          <div className='w-full flex justify-end'>
            <Switch
              loading={changeId === row.id}
              disabled={!!changeId && changeId !== row.id}
              checked={!!value}
              onChange={e => changeStatus(e.target.checked, row)}
            />
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'auto_renew',
          value,
        })
      },
    },
  ]

  return (
    <div>
      <Table
        className='mb-20'
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
      />
    </div>
  )
}
