import { useState } from 'react'
import { PopupModal } from 'react-calendly'
import { useAppSelector } from 'redux/hooks'

import Button from 'components/Button'
import Label from 'components/Label'
import { RUSHABLETIMEZONE } from 'data/constant'

import { DateAndTimePicker } from 'components/DatePicker'
import PersonName from 'components/PersonName'

interface Tprops {
  onboardingInfo: Record<string, any>
  onSuccess: () => void
}
export default function SuccessCall({ onboardingInfo, onSuccess }: Tprops) {
  const detail = useAppSelector(s => s.account.detail)
  const profile = useAppSelector(s => s.auth.profile)
  const [calendlyOpen, setCalendary] = useState(false)

  const schedule = onboardingInfo?.account?.account_call_schedule || {}
  const brandName =
    detail?.location?.brand?.name || detail?.signup_form?.brand_name
  const locationName =
    detail?.location?.name || detail?.signup_form?.location_name
  const poc = onboardingInfo.users?.find(
    (v: any) => !!v.success_point_of_contact,
  )
  return (
    <>
      {schedule.start_time ? (
        <>
          <div className='mt-4 flex'>
            <div className='mr-4 flex-1'>
              <Label className='mb-2'>LOCAL SUCCESS CALL TIME</Label>
              <DateAndTimePicker
                value={schedule.start_time}
                disabled={true}
                onChange={(date: any, dateString: any) => {}}
                timezone={onboardingInfo?.address?.timezone || RUSHABLETIMEZONE}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
            <div className='flex-1'>
              <Label className='mb-2'>RUSHABLE SUCCESS CALL TIME</Label>
              <DateAndTimePicker
                value={schedule.start_time}
                timezone={RUSHABLETIMEZONE}
                disabled={true}
                onChange={(date: any, dateString: any) => {}}
                showTime={{
                  use12Hours: true,
                  minuteStep: 15,
                  format: 'HH:mm',
                }}
              />
            </div>
          </div>
          <div className='flex mt-4'>
            <div className='mr-4 flex-1 bg-dark-700 rounded-lg px-4 py-2 flex items-center'>
              <PersonName
                avatarSize={20}
                avatar={profile?.userPhoto}
                name={profile?.userName}
              />
            </div>
            <Button
              className='flex-1'
              theme='outlined'
              color='secondary'
              onClick={() => setCalendary(true)}
            >
              RESCHEDULE
            </Button>
          </div>
          <div className='mt-4 bg-dark-700 rounded-lg px-4 py-2 flex items-center'>
            <PersonName avatarSize={20} name={schedule.invitee || '-'} />
          </div>
        </>
      ) : (
        <div>
          <Label className='mb-2'>SUCCESS CALL</Label>
          <Button
            className='w-full'
            theme='outlined'
            color='secondary'
            onClick={() => setCalendary(true)}
          >
            SCHEDULE CALL
          </Button>
        </div>
      )}

      <PopupModal
        url={onboardingInfo?.welcome_call_links}
        // url={'https://calendly.com/lei-18/30min'}
        prefill={{
          // '默认填成 POC 名字'
          name: poc ? `${poc.first_name} ${poc.last_name}` : '',
          // '默认填成 POC email'
          email: poc ? poc.email : '',
          customAnswers: {
            // 'Restaurant Name: 默认填成 Brand Name @ Location Name'
            a1: `${brandName}@${locationName}`,
            // 'Additional Note: 空'
            a2: '',
            // 'Send text message to: 默认填成 POC cellphone'
            a3: poc ? poc.phone : '',
          },
        }}
        onModalClose={() => {
          setCalendary(false)
          onSuccess()
        }}
        open={calendlyOpen}
        rootElement={document.getElementById('root')!}
      />
    </>
  )
}
