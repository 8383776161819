import React from 'react'
import Table from 'components/Table'
import BadgeText from 'components/BadgeText'
import DateAndTime from 'components/DateAndTime'
import Processing from 'components/Processing'
import PayoutSchedule from 'components/PayoutSchedule'
import { useNavigate } from 'react-router-dom'
import { currencyFormatter } from 'utils/digit'
import { useAppSelector } from 'redux/hooks'

import type { TPayout } from '../helpers/constant'
import moment from 'moment'

type TRowProp = {
  [index: string]: any
}

export type TPayoutTableProps = {
  loading?: boolean
  total: number
  current: number
  pageSize: number
  data: Array<TRowProp>
  handleFilterChange: (type: string, value: any) => void
  onPageNoChange: (page: number) => void
}

export default function PayoutTable({
  loading,
  total,
  current,
  pageSize,
  data,
  handleFilterChange,
  onPageNoChange,
}: TPayoutTableProps) {
  const { filterForm } = useAppSelector(state => state.payouts)
  const { sort_by, sort } = filterForm
  const navigate = useNavigate()
  const clickedRow = (item: any) => {
    navigate(
      `/payout/detail?date=${item.payout_created_date}&type=${item.type}&status=${item.status}`,
    )
  }

  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-120',
      custom: (value: string) => {
        let color: any = ''
        switch (value) {
          case 'scheduled':
            color = 'default'
            value = 'Scheduled'
            break
          case 'processing':
            color = 'blue'
            value = 'Processing'
            break
          case 'done':
            color = 'green'
            value = 'Done'
            break
          case 'stuck':
            color = 'red'
            value = 'Partial Stuck'
            break
        }
        return <BadgeText value={value} color={color} />
      },
    },
    {
      key: 'accounts',
      name: 'ACCOUNTS',
      align: 'right',
    },
    {
      key: 'progress',
      name: 'PROGRESS',
      className: 'w-60',
      align: 'right',
      custom: (value: any, row: any) => {
        let percent = 0
        let number = 0
        let color = ''
        number = parseFloat(
          row.status === 'scheduled'
            ? row.accounts
            : row.status === 'processing'
            ? row.pending
            : row.status === 'done'
            ? row.accounts - row.done
            : row.stuck,
        )
        percent = Math.round((row.done / row.accounts) * 10000) / 100.0
        color =
          row.status === 'processing'
            ? 'blue'
            : row.status === 'stuck'
            ? 'red'
            : row.status === 'done'
            ? 'green'
            : 'light-700'
        return (
          <div className='flex justify-end'>
            <Processing
              showCount={!(row.status === 'done' && !number)}
              count={number}
              percent={percent.toFixed(0)}
              color={color}
            />
          </div>
        )
      },
    },
    {
      key: 'estimate_arrival',
      name: 'MONTH',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'month' ? sort : '',
      custom: (value: string, item: any) => {
        return (
          <div className='flex justify-end'>
            {value ? (
              <DateAndTime
                format='MMMM YYYY'
                parse='YYYY-MM-DD'
                value={value}
                noExpired={true}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'month',
          value,
        })
      },
    },
    {
      key: 'type',
      name: 'TYPE',
      align: 'right',
      custom: (value: string) => {
        const newValue =
          value === 'daily' ? 'Daily' : value === 'weekly' ? 'Weekly' : '-'
        return <div>{newValue}</div>
      },
    },
    {
      key: 'payout_created_date',
      name: 'SCHEDULE',
      className: 'w-60',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'schedule' ? sort : '',
      custom: (value: string, row: any) => {
        let weekOfDay: any = parseInt(moment(value).format('E'))
        let last_sunday = moment(value)
          .subtract((weekOfDay % 7) + (row.type === 'daily' ? 0 : 7), 'days')
          .format('YYYY-MM-DD')

        return (
          <div className='flex justify-end'>
            {value ? (
              <PayoutSchedule
                payoutType={row.type}
                payoutRanges={last_sunday}
                payoutDate={value}
                intoAccountDate={row.estimate_arrival}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'schedule',
          value,
        })
      },
    },
    {
      key: 'amount',
      name: 'AMOUNT',
      align: 'right',
      custom: (value: any) => {
        return <div>{value ? currencyFormatter(value) : '-'}</div>
      },
    },
  ]

  return (
    <div>
      <Table
        className='mb-20'
        loading={loading}
        columns={columns}
        data={data}
        paginationShow
        total={total}
        current={current}
        resizePageSize={pageSize}
        onPageNoChange={onPageNoChange}
        clickedRow={clickedRow}
      />
    </div>
  )
}
