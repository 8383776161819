import React, { useState, useEffect } from 'react'

import { AdminLayout } from 'components/Layout'
import { BrandSelect } from 'components/Select'
import Button from 'components/Button'
import useDebounce from 'hooks/useDebounce'
import { toast } from 'react-toastify'
import { getBrands, getDomainsList, handleUpdateRenewed } from 'redux/domains'

import DomainsRight from './components/DomainsRight'
import DomainsTable from './components/DomainsTable'
import PageLoading from 'components/PageLoading'

export default function DomainsPage() {
  const [open, setOpen] = useState(false)

  const [total, setTotal] = useState(0)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [changeId, setChangeId] = useState(0)
  const [sortData, setSortData] = useState({
    sort_by: '',
    sort: '',
  })

  const [filterForm, setFilterForm] = useState<any>({
    my_accounts_only: 0,
    brand_name: '',
    page: 1,
    per_page: 40,
  })

  const getAsyncBrandData = async (inputValue?: any): Promise<any> => {
    const res = await getBrands(inputValue || '')
    const brands = res.map((item: any) => {
      return { ...item, value: item.id }
    })
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(brands)
      }, 500)
    })
  }

  const handleFilterChange = (type: string, value: any) => {
    let newForm = { ...filterForm }
    if (type === 'sort') {
      const sortForm = {
        sort_by: value.value ? value.type : '',
        sort: value.value,
      }
      newForm = {
        ...filterForm,
        ...sortForm,
      }
      setSortData(sortForm)
    } else {
      newForm = {
        ...filterForm,
        [type]: value,
      }
    }
    if (type === 'brand_name') {
      setFilterForm(newForm)
      handleDomains(newForm)
    } else {
      getTable({ ...newForm, page: 1 })
    }
  }

  const getTable = async (form: any) => {
    // 请求 api
    setLoading(true)
    try {
      setFilterForm(form)
      const res = await getDomainsList(form)
      setData(res.data)
      setTotal(res.total)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setLoading(false)
  }
  const onPageNoChange = (page: number) => {
    getTable({ ...filterForm, page })
  }

  const handleDomains = useDebounce((formData: any) => {
    getTable({ ...formData, page: 1 })
  }, 500)

  const changeRowStatus = async (status: boolean, item: any) => {
    setChangeId(item.id)
    try {
      const res = await handleUpdateRenewed(item.id)
      getTable(filterForm)
    } catch (e: any) {
      if (e && e.message) {
        toast.error(e.message)
      }
    }
    setChangeId(0)
  }

  useEffect(() => {
    getTable(filterForm)
  }, [])

  return (
    <AdminLayout>
      {loading ? (
        <PageLoading />
      ) : (
        <div className='px-10 py-8'>
          <div className='flex justify-between'>
            <BrandSelect
              className='w-80'
              placeholder='Search'
              value={filterForm.brand_name}
              onChange={(value, item) => {
                handleFilterChange(
                  'brand_name',
                  value === 'All Brands' ? '' : value,
                )
              }}
              isShowLocation={false}
              isShowAllBrands={false}
              requestApi={getAsyncBrandData}
            />

            <div className='flex'>
              <Button
                className='ml-8'
                theme='contained'
                color='secondary'
                onClick={() => setOpen(true)}
              >
                PURCHASE DOMAIN
              </Button>
            </div>
          </div>
          <div className='mt-8'>
            <DomainsTable
              loading={loading}
              changeId={changeId}
              total={total}
              current={filterForm.page}
              pageSize={filterForm.per_page}
              sortData={sortData}
              handleFilterChange={handleFilterChange}
              onPageNoChange={onPageNoChange}
              changeStatus={(value: boolean, row: any) =>
                changeRowStatus(value, row)
              }
              data={data}
            />
          </div>
          <DomainsRight
            open={open}
            toggle={value => {
              value &&
                getTable({
                  ...filterForm,
                  page: 1,
                })
              setOpen(!open)
            }}
          />
        </div>
      )}
    </AdminLayout>
  )
}
