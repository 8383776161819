import { useEffect, useState } from 'react'
import { Select, BrandSelect } from 'components/Select'
import { RangePicker } from 'components/DatePicker'
import { IOptions } from 'components/Select/help'
import { Loading } from '@rushable/icons'

import {
  TFilterForm,
  statusOptions,
  filterTypeOptions,
} from '../helpers/constant'

export type TSearchFilterProps = {
  autoLoading: boolean
  locationOptions: IOptions[]
  setLocationOptions: (arg1: IOptions[]) => void
  filterForm: TFilterForm
  handleFormChange: (type: string, value: string | number) => void
}

export default function SearchFilter({
  autoLoading,
  locationOptions,
  setLocationOptions,
  filterForm,
  handleFormChange,
}: TSearchFilterProps) {
  const { status, brand_name, location_id, type } = filterForm
  const onChange = (item: any, rest: any) => {
    const { locations } = rest
    handleFormChange('brand_name', item)
    if (locations && locations.length > 0) {
      setLocationOptions(
        [{ value: '', label: 'All Locations' }].concat(
          locations.map((loc: any) => {
            return {
              label: loc.name,
              value: loc.id,
            }
          }),
        ),
      )
    }
  }

  return (
    <div className='mb-8 mr-4 flex items-center justify-between'>
      <div className='flex space-x-4'>
        <Select
          className='w-216'
          options={statusOptions}
          value={status}
          onChange={value => handleFormChange('status', value)}
        />
        <BrandSelect
          className='w-216'
          placeholder='Search'
          value={brand_name}
          apiType='orders'
          isShowLocation={false}
          isShowAllBrands={false}
          onChange={onChange}
        />
        {brand_name && brand_name !== 'All Brands' ? (
          <Select
            key={location_id}
            className='w-216'
            options={locationOptions}
            value={location_id}
            onChange={value => handleFormChange('location_id', value)}
          />
        ) : null}
        <Select
          className='w-216'
          options={filterTypeOptions}
          value={type || ''}
          onChange={value => handleFormChange('type', value)}
        />
        <RangePicker
          className='w-256'
          onChange={(e: any) => {
            handleFormChange('dates', e)
          }}
        />
      </div>
      {autoLoading && <Loading />}
    </div>
  )
}
