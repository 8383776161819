import React from 'react'
import cn from 'classnames'
import Select from 'react-select'
import Label from 'components/Label'
import {
  IOptions,
  customStyles,
  customTheme,
  Control,
  customMultiOptions,
  customSingleOption,
  customPersonOption,
  IndicatorsContainer,
  MultiValueContainer,
} from './help/index'
import PersonName from 'components/PersonName'

const prefix = 'ru-form-select'

// @ts-ignore
const getFormatValue = (isMulti, value, options) => {
  let formatValue
  if (isMulti) {
    formatValue = (options as IOptions[])?.reduce(
      (acc: IOptions[], cur: IOptions) => {
        if (value?.includes(cur.value)) {
          acc.push(cur)
        }
        return acc
      },
      [],
    )
  } else {
    // @ts-ignore
    formatValue = options.find(item => item.value === value)
  }
  return formatValue
}

export type TFormSelect = {
  className?: string
  label?: string | React.ReactElement
  disabled?: boolean
  options: IOptions[] | []
  placeholder?: string
  value?: any | any[]
  defaultValue?: any | any[]
  onChange: (item: any, rest: any) => void
  isMulti?: boolean
  isPerson?: boolean
  isRtl?: boolean
  hideIndicator?: boolean
}

export default function FormSelect({
  className = '',
  label,
  disabled,
  options = [],
  placeholder = '',
  value,
  defaultValue = '',
  isMulti,
  isPerson,
  isRtl,
  hideIndicator,
  onChange,
}: TFormSelect) {
  const formatValue = getFormatValue(isMulti, value, options)
  const formatDefaultValue = getFormatValue(isMulti, defaultValue, options)

  const handleChange = (item: IOptions) => {
    let value
    if (isMulti) {
      value = item?.map((v: IOptions) => v.value) || []
    } else {
      value = item.value
    }
    onChange && onChange(value, item)
  }
  return (
    <div className={cn(className, 'w-full')}>
      {label && <Label className='mb-2'>{label}</Label>}
      <Select<IOptions>
        // @ts-ignore
        icon={
          isPerson && (
            <PersonName avatarSize={20} avatar={formatValue?.img_url} />
          )
        }
        // menuIsOpen={true}
        isRtl={isRtl}
        className={cn(prefix)}
        isDisabled={disabled}
        options={options}
        isMulti={isMulti as any}
        value={value ? formatValue : undefined}
        defaultValue={formatDefaultValue}
        onChange={handleChange as any}
        placeholder={placeholder}
        backspaceRemovesValue={true}
        isClearable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti}
        components={{
          Control,
          IndicatorsContainer: hideIndicator ? () => null : IndicatorsContainer,
          MultiValueContainer,
          Option: isMulti
            ? customMultiOptions
            : isPerson
            ? customPersonOption
            : customSingleOption,
        }}
        theme={customTheme}
        styles={customStyles}
      />
    </div>
  )
}
