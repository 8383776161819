import { useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'redux/hooks'
import currency from 'currency.js'
import { FaviconColor, SolidForbidden } from '@rushable/icons'
import DateAndTime from 'components/DateAndTime'
import PaymentInfo from 'components/PaymentInfo'
import SaleComparison from 'components/SaleComparison'
import Contribution from 'components/Contribution'
import FeatureTracking from 'components/FeatureTracking'
import AttentionStatus from 'components/AttentionStatus'
import GBPTracking from 'components/GBPTracking'
import PersonName from 'components/PersonName'
import Button from 'components/Button'
import StageProgress from 'components/StageProgress'
import Pricing from 'components/Pricing'
import Modal from 'components/Modal'
import ParagraphInput from 'components/ParagraphInput'
import { Select } from 'components/Select'
import { formatPhoneNumber } from 'utils/digit'
import { DOMAIN } from 'data/constant'
import { terminateApi, getAccountDetailApi } from 'redux/account'
import SubscriptionTooltip from './SubscriptionTooltip'
import { TEMINATEOPTIONS, Ttabstatus } from '../helpers/constant'
import {
  RUSHABLE_MONTHLY_PLAN_ID,
  RUSHABLE_ANNUAL_PLAN_ID,
} from 'utils/constant'
interface Tsidebar {
  type: Ttabstatus // "Signup" | "Onboarding" | "Success" | "Menu" | "Vault"
  data: any
}

export default function AccountSideBar({ data, type }: Tsidebar) {
  const urlParams = useParams()
  const dispatch = useAppDispatch()
  const [terminate, setTerminate] = useState({
    open: false,
    loading: false,
    reason: '',
    comment: '',
    errorModal: false,
    message:
      'You are not authorized to perform this action, please contact lv2 support.',
  })
  const handleTermiate = async () => {
    setTerminate(prev => ({ ...prev, loading: true }))
    console.log(terminate)
    try {
      const res = await terminateApi(urlParams.id!, {
        reason: terminate.reason,
        comment: terminate.comment,
      })
      toast.success(res.message)
      setTerminate(prev => ({ ...prev, reason: '', comment: '' }))
      // 刷新数据
      dispatch(getAccountDetailApi(urlParams.id!))
    } catch (error: any) {
      setTerminate(prev => ({
        ...prev,
        reason: '',
        comment: '',
        open: false,
      }))
      // 两个弹框同时出现会造成滚动条无法滚动
      const message =
        error.status === 422
          ? error.message
          : 'You are not authorized to perform this action, please contact lv2 support.'
      setTimeout(() => {
        setTerminate(prev => ({ ...prev, errorModal: true, message }))
      }, 500)
    }
    setTerminate(prev => ({ ...prev, loading: false }))
  }

  const {
    location = {},
    signup_form = {},
    onboarding = {},
    status, // 当前记录状态
    price,
    convenience_fee,
  } = data || {}

  const brand = location?.brand || {}

  const renderCuisine = () => {
    let arr = []
    const locationCuisine = brand.brand_cuisine || []
    const signupCuisine = signup_form?.signup_form_cuisine_types || []
    if (locationCuisine.length) {
      arr = locationCuisine.map((v: any) => v?.cuisine_type?.name)
    } else {
      arr = signupCuisine.map((v: any) => v?.cuisine_type?.name)
    }
    return arr.length ? arr.join(', ') : '-'
  }

  const renderPricing = () => {
    const rushable_plan_id = signup_form.rushable_plan_id
    if (rushable_plan_id) {
      if (rushable_plan_id === Number(RUSHABLE_MONTHLY_PLAN_ID)) {
        return '$119.00/mo'
      }
      if (rushable_plan_id === Number(RUSHABLE_ANNUAL_PLAN_ID)) {
        return '$1180.00/yr'
      }
    }
    if (!price) {
      return '-'
    }
    return (
      <>
        {!price && !convenience_fee ? (
          '-'
        ) : (
          <Pricing
            store={price ? currency(price).format() : '-'}
            userGroups={
              convenience_fee ? currency(convenience_fee).format() : '-'
            }
          />
        )}
      </>
    )
  }

  const domain = brand.default_domain || {}
  const address = signup_form?.location_address || {}
  const features = location?.online_order_config || {}
  const featuresProps = {
    pickup: !!features.order_pickup_config?.enabled,
    delivery:
      !!features.order_doordash_delivery_config?.enabled ||
      !!features.order_in_house_config?.enabled,
    dineIn: !!features.order_contactless_dinein_config?.enabled,
    email: !!location?.location_business_setting?.marketing_phone_number, // 纸飞机 至少开启了两个 automated campaign
    phone: !!data?.paper_plane, // 电话是subscribe了phone number
  }
  const crm_closer = onboarding.crm_closer || {}
  const projectLead = onboarding.project_lead || {}

  const isTerminated = status === 'canceled' || 'withdrawn'
  const timezone = location?.address?.timezone || address?.timezone
  const locationName = location?.name || signup_form?.location_name
  return (
    <div
      className='w-400 border-r border-dark-100 text-light-300 text-xs'
      style={{ minHeight: 'calc(100vh - 72px)' }}
    >
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Brand</div>
        <div className='text-right'>
          <div className='text-sm text-light-500'>
            {brand.name || signup_form?.brand_name || '-'}
          </div>
          <div>{location?.brand_id || signup_form?.brand_id || '-'}</div>
        </div>
      </div>
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Location</div>
        <div className='text-right'>
          <div className='text-sm text-light-500 truncate w-244'>
            {locationName ? `@${locationName}` : '-'}
          </div>
          <div className='text-light-100'>
            {location?.id || signup_form?.location_id || '-'}
          </div>
        </div>
      </div>
      {/* signup 没有 */}
      {type !== 'Signup' && (
        <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
          <div>Domain</div>
          <div className='flex items-center'>
            {domain.hostname ? (
              <a
                href={`https://${domain.hostname}`}
                target='_blank'
                rel='noreferrer'
              >
                <span className='text-sm text-light-500'>
                  {domain.hostname}
                </span>
              </a>
            ) : (
              <span className='text-sm text-light-500'>-</span>
            )}
            {domain.domain_registration_id && (
              <FaviconColor
                style={{ mixBlendMode: 'difference' }}
                className='w-3 ml-1'
              />
            )}
          </div>
        </div>
      )}
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Local time</div>
        <DateAndTime
          noExpired={true}
          value={moment().format('YYYY-MM-DD HH:mm:ss')}
          format='MMM DD @ hh:mm A'
          timezone={timezone || 'America/Chicago'}
        />
      </div>
      <div className='h-16 px-10 py-3 flex justify-between items-center border-b border-dark-100'>
        <div>Stage</div>
        <div className='text-light-500'>
          {urlParams.id !== 'create' && onboarding.id ? (
            <StageProgress
              className='w-20 text-light-700'
              popLeft={false}
              popTop={false}
              statusName={data?.stage}
              onboarding={onboarding}
              signupForm={signup_form}
            />
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Cuisine</div>
        <div className='text-sm text-light-500 truncate w-244 text-right'>
          {renderCuisine()}
        </div>
      </div>
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Phone</div>
        <div className='text-sm text-light-500'>
          {formatPhoneNumber(location?.phone || signup_form?.location_phone) ||
            '-'}
        </div>
      </div>
      {!['Signup', 'Onboarding'].includes(type) && (
        <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
          <div>Subscription</div>
          <SubscriptionTooltip data={data.subscriptions} />
        </div>
      )}
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Pricing</div>
        <div className='flex item-center text-sm text-light-500'>
          {renderPricing()}
        </div>
      </div>
      <div className='h-16 px-10 py-3 flex justify-between items-center border-b border-dark-100'>
        <div>Payment Profile</div>
        <PaymentInfo
          last4={data?.last_4}
          brand={data?.brand}
          expireDate={data?.exp}
        />
      </div>
      {/* 28 days/Comparsion/Contribution  signups onboarding 不存在这三项 */}
      {!['Signup', 'Onboarding'].includes(type) && (
        <>
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>28 days</div>
            <div className='text-sm text-light-500'>
              {data.total ? currency(data.total).format() : '-'}
            </div>
          </div>
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>Comparison</div>
            <div className='text-light-500'>
              {data.comparison?.length === 0 ? (
                '-'
              ) : (
                <SaleComparison
                  className='my-4'
                  weeklySales={data.comparison}
                />
              )}
            </div>
          </div>
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>Contribution</div>
            <div className='text-light-500'>
              <Contribution num={data.contribution} />
            </div>
          </div>
        </>
      )}
      {/* Features、Attention、GBP Link  signups 不存在这三项 */}
      {type !== 'Signup' && (
        <>
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>Features</div>
            <FeatureTracking className='!p-0' {...featuresProps} />
          </div>
        </>
      )}

      {type !== 'Signup' && (
        <>
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>Attention</div>
            <AttentionStatus
              className='w-auto'
              handRaise={!!data.has_attention}
              google={!!data.GBP_need_claim}
            />
          </div>
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>GBP Links</div>
            <GBPTracking
              className='w-auto'
              websiteLink={!!onboarding.GBP_website_tracking}
              orderingLink={!!onboarding.GBP_ordering_tracking}
            />
          </div>
        </>
      )}

      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Address 1</div>
        <div className='text-sm text-light-500 text-right truncate w-244'>
          {address.line_1 || '-'}
        </div>
      </div>
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Address 2</div>
        <div className='text-sm text-light-500 text-right  truncate w-244'>
          {address.line_2 || '-'}
        </div>
      </div>
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>City/State/Zip</div>
        <div className='text-sm text-light-500 text-right  truncate w-244'>
          {address.city}, {address.state} {address.zipcode}
        </div>
      </div>
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Closer</div>
        <PersonName
          avatar={
            crm_closer.profile_photo
              ? `${DOMAIN}${crm_closer.profile_photo}`
              : undefined
          }
          name={`${crm_closer?.user?.first_name || '-'} ${
            crm_closer?.user?.last_name || ''
          }`}
        />
      </div>
      <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
        <div>Signup created</div>
        <div className='text-sm text-light-500'>
          {signup_form.created_at
            ? moment(signup_form.created_at).format('MMM DD, YYYY')
            : '-'}
        </div>
      </div>
      {/* signup done、Assignee、Onboarding done   signup 不存在这三项 */}
      {type !== 'Signup' && (
        <>
          <div className='h-16 px-10 py-3 flex justify-between items-center border-b border-dark-100'>
            <div>Signup done</div>
            <div className='text-sm text-light-500'>
              {signup_form.done_at
                ? moment(signup_form.done_at).format('MMM DD, YYYY')
                : '-'}
            </div>
          </div>
          <div className='h-16 px-10 py-3 flex justify-between items-center border-b border-dark-100'>
            <div>Onboarding</div>
            <PersonName
              avatar={
                projectLead.profile_photo
                  ? `${DOMAIN}${projectLead.profile_photo}`
                  : undefined
              }
              name={`${projectLead.first_name || '-'} ${
                projectLead.last_name || ''
              }`}
            />
          </div>
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>Onboarding done</div>
            <div className='text-sm text-light-500'>
              {onboarding.done_at
                ? moment(onboarding.done_at).format('MMM DD, YYYY')
                : '-'}
            </div>
          </div>
        </>
      )}
      {urlParams.id !== 'create' &&
        (isTerminated ? (
          <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
            <div>Action</div>
            <Button
              className={type === 'Success' ? 'text-red' : 'text-purple'}
              size='small'
              theme='text'
              onClick={() => setTerminate(prev => ({ ...prev, open: true }))}
              iconBefore={<SolidForbidden size={12} />}
            >
              {type === 'Success' ? 'CANCEL' : 'WITHDRAW'}
            </Button>
          </div>
        ) : (
          `${onboarding.onboarding_cancellation?.reason}`
        ))}
      <Modal
        open={terminate.open}
        title='TERMINATE'
        cancelText='CLOSE'
        okText='CONFIRM TO CANCEL'
        okButtonProps={{
          loading: terminate.loading,
          color: 'warning',
          disabled: !terminate.reason || !terminate.comment,
        }}
        toggle={() => setTerminate(prev => ({ ...prev, open: false }))}
        onOk={handleTermiate}
      >
        <Select
          className='mb-4'
          label='REASON'
          placeholder='Select'
          value={terminate.reason}
          options={TEMINATEOPTIONS}
          onChange={(value, item) => {
            setTerminate(prev => ({ ...prev, reason: value }))
          }}
        />
        <ParagraphInput
          value={terminate.comment}
          name='COMMENT'
          label='COMMENT'
          placeholder='Input'
          onChange={(e: any) => {
            setTerminate(prev => ({ ...prev, comment: e.target.value }))
          }}
        />
      </Modal>
      <Modal
        open={terminate.errorModal}
        title='NOT AUTHORIZED'
        isCancelBtn={false}
        okText='CLOSE'
        toggle={() =>
          setTerminate(prev => ({
            ...prev,
            errorModal: false,
            message:
              'You are not authorized to perform this action, please contact lv2 support.',
          }))
        }
      >
        <div className='text-center text-light-700'>{terminate.message}</div>
      </Modal>
    </div>
  )
}

// {
//   /* 暂不需要 */
// }
// {
//   /* {!['Signup', 'Onboarding'].includes(type) && (
//         <>
//           <div className='h-16 px-10 py-3  flex justify-between items-center border-b border-dark-100'>
//             <div>OFG</div>
//             <Switch
//               checked={true}
//               onChange={e => {
//                 console.log(e.target.checked)
//               }}
//             />
//           </div>
//         </>
//       )} */
// }
