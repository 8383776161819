import type { TMode } from 'components/StageSelect'

export const WEBSITEOPTIONS = [
  // website_preference has_existing_domain delegate_dns_or_website
  {
    value: 'full_website-0-1',
    label: 'Full website • New domain • Update by Rushable',
    option: {
      website_preference: 'full_website',
      has_existing_domain: 0,
      delegate_dns_or_website: 1,
    },
  },
  {
    value: 'full_website-1-1',
    label: 'Full website • Existing domain • Update by Rushable',
    option: {
      website_preference: 'full_website',
      has_existing_domain: 1,
      delegate_dns_or_website: 1,
    },
  },
  {
    value: 'full_website-1-0',
    label: 'Full website • Existing domain • Self update',
    option: {
      website_preference: 'full_website',
      has_existing_domain: 1,
      delegate_dns_or_website: 0,
    },
  },
  {
    value: 'ordering_page_only-1-1',
    label: 'Ordering only • Existing domain • Update by Rushable',
    option: {
      website_preference: 'ordering_page_only',
      has_existing_domain: 1,
      delegate_dns_or_website: 1,
    },
  },
  {
    value: 'ordering_page_only-1-0',
    label: 'Ordering only • Existing domain • Self update',
    option: {
      website_preference: 'ordering_page_only',
      has_existing_domain: 1,
      delegate_dns_or_website: 0,
    },
  },
]

export const getWebsiteValue = (
  website_preference: string,
  has_existing_domain: number,
  delegate_dns_or_website: number,
): string => {
  // 直接取值不需要考虑 null / undefined
  return `${website_preference}-${has_existing_domain}-${delegate_dns_or_website}`
}

export const GBPOPTIONS = [
  // has_gmb_ownership  need_help_regain_gmb delegate_gmb_update
  // need_help_regain_gmb self_claim,rushable_claiming
  {
    value: '1-0-1',
    label: 'Has GBP ownership • Update by Rushable',
    option: {
      has_gmb_ownership: 1,
      delegate_gmb_update: 1,
    },
  },
  {
    value: '1-0-0',
    label: 'Has GBP ownership • Self update',
    option: {
      has_gmb_ownership: 1,
      delegate_gmb_update: 0,
    },
  },
  {
    value: '0-rushable_claiming-1',
    label: 'No GBP ownership • Claim by Rushable • Update by Rushable',
    option: {
      has_gmb_ownership: 0,
      need_help_regain_gmb: 'rushable_claiming',
      delegate_gmb_update: 1,
    },
  },
  {
    value: '0-self_claim-1',
    label: 'No GBP ownership • Self claim • Update by Rushable',
    option: {
      has_gmb_ownership: 0,
      need_help_regain_gmb: 'self_claim',
      delegate_gmb_update: 1,
    },
  },
  {
    value: '0-self_claim-0',
    label: 'No GBP ownership • Self claim • Self update',
    option: {
      has_gmb_ownership: 0,
      need_help_regain_gmb: 'self_claim',
      delegate_gmb_update: 0,
    },
  },
]

export const getGBPValue = (
  has_gmb_ownership: number,
  need_help_regain_gmb: number,
  delegate_gmb_update: number,
): string => {
  // 需要考虑 null / undefined 情况
  const ownership =
    has_gmb_ownership === null ? 'null' : Number(!!has_gmb_ownership)

  const gmb_update =
    delegate_gmb_update === null ? 'null' : Number(!!delegate_gmb_update)

  return `${ownership}-${need_help_regain_gmb || 0}-${gmb_update}`
}

export type TCount = {
  churned: number
  canceling: number
  draft: number
  live: number
  onboarding: number
  signup: number
  withdrawal: number
  all: number
}

export type TOrder = {
  sort_by: string
  sort: string
}

export type TData = {
  [index: string]: any
}[]

export type Ttabstatus = 'Signup' | 'Onboarding' | 'Success' | 'Menu' | 'Vault'

// ADD LOGISTIC ITEM  CHOOSE ITEM
export const CHOOSEITEMOPTIONS = [
  { label: 'Ticket Printer', value: 'Ticket Printer' },
  { label: 'Tablet 8" (Wi-Fi)', value: 'Tablet 8" (Wi-Fi)' },
  { label: 'Tablet 8" (LTE)', value: 'Tablet 8" (LTE)' },
  // { label: 'Promo Card x500', value: 'Promo Card x500' },
  { label: 'Promo Card x1000', value: 'Promo Card x1000' },
  { label: 'Promo Card x2000', value: 'Promo Card x2000' },
  { label: 'Table Tent x25', value: 'Table Tent x25' },
]

export const TEMINATEOPTIONS = [
  { label: 'Too many system bugs', value: 'many_system_bugs' },
  { label: 'Too hard to use', value: 'too_hard_to_use' },
  { label: 'Too expensive', value: 'too_expensive' },
  { label: 'Found better solution', value: 'found_better_solution' },
  { label: 'Selling/closing', value: 'closing_down' },
  { label: 'Other reason', value: 'other' },
]

export const SCHEDULEOPTION = [
  { label: 'Follow store hours', value: 'same_as_store' },
  { label: 'Custom schedule', value: 'customize' },
]

export type TFilterParams = {
  page: number
  // Stage (required)
  stage: TMode
  // Brand name
  brand_name?: string
  // Sales team
  team_id: string
  // Deal closer
  closer_id: string
  // Onboarding issue
  issue: string
  // GBP links
  gbp: string
  // Feature off
  feature: string
  // Onboarding assignee
  assignee_id: string
}
