import React from 'react'
import { useAppSelector } from 'redux/hooks'
import Table from 'components/Table'
import Contribution from 'components/Contribution'
import PersonName from 'components/PersonName'

export type TOverallInsightsTableProp = {
  loading?: boolean
  onPageNoChange?: (value: number) => void
  handleFilterChange: (type: string, value: any) => void
  goDetail: (item: any) => void
}

export default function OverallInsightsTable({
  loading,
  onPageNoChange,
  handleFilterChange,
  goDetail,
}: TOverallInsightsTableProp): JSX.Element {
  const { page, total, filterForm, data, pageSize } = useAppSelector(
    state => state.sales,
  )
  const { sort_by, sort } = filterForm
  const columns = [
    {
      key: 'closer',
      name: 'SALES REP',
      className: 'w-48',
      custom: (item: any) => {
        const { profile_photo, first_name, last_name } = item
        return (
          <div className='flex items-center'>
            <PersonName
              className='w-full'
              avatar={profile_photo}
              name={`${first_name} ${last_name}`}
            />
          </div>
        )
      },
    },
    {
      key: 'closer',
      name: 'TEAM',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'team' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'team',
          value,
        })
      },
      align: 'right',
      custom: (closer: any) => {
        const { team_name } = closer
        return <div className='text-ellipsis'>{team_name}</div>
      },
    },
    {
      key: 'closer_total',
      name: 'PROMOTION',
      className: 'w-40',
      sortable: true,
      sortDirection: sort_by === 'promotion' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'promotion',
          value,
        })
      },
      align: 'right',
      custom: (value: any) => {
        return (
          <div>
            {!value ? '-' : value > 200 ? '🏅 Senior Earned' : `${value}/200`}
          </div>
        )
      },
    },
    {
      key: 'actual_quantity',
      name: 'LAUNCH',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'signups' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'signups',
          value,
        })
      },
      align: 'right',
      custom: (value: string) => {
        return <div>{Number(value) || 0}</div>
      },
    },
    {
      key: 'effort',
      name: 'EFFORT',
      className: 'w-120',
      sortable: true,
      sortDirection: sort_by === 'effort' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'effort',
          value,
        })
      },
      align: 'right',
      custom: (value: string) => {
        return <div>{Number(value) || 0}</div>
      },
    },
    {
      key: 'title_earned',
      name: 'TITLE EARNED',
      align: 'center',
      custom: (value: any, row: any) => {
        return (
          <div className='flex justify-center space-x-2'>
            <div
              className={`${
                !row.grinder && 'opacity-[0.16]'
              } bg-dark-100 rounded px-2 py-0.5 flex justify-center items-center text-light-700 text-xs font-medium`}
            >
              👷 Grinder
            </div>
            <div
              className={`${
                !row.carrier && 'opacity-[0.16]'
              } bg-dark-100 rounded px-2 py-0.5 flex justify-center items-center text-light-700 text-xs font-medium`}
            >
              🦸 Carrier
            </div>
            <div
              className={`${
                !row.mage && 'opacity-[0.16]'
              } bg-dark-100 rounded px-2 py-0.5 flex justify-center items-center text-light-700 text-xs font-medium`}
            >
              🧙 Mage
            </div>
            <div
              className={`${
                !row.maverick && 'opacity-[0.16]'
              } bg-dark-100 rounded px-2 py-0.5 flex justify-center items-center text-light-700 text-xs font-medium`}
            >
              🦹 Maverick
            </div>
          </div>
        )
      },
    },
    {
      key: 'exp',
      name: 'EXP',
      className: 'w-96',
      sortable: true,
      sortDirection: sort_by === 'exp' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'exp',
          value,
        })
      },
      align: 'right',
      custom: (value: any) => {
        return <div>{value ? `${value} month` : '-'}</div>
      },
    },
    {
      key: 'cell_contribution_in_30_days',
      name: 'AVE 30-DAY CONT',
      className: 'w-40',
      align: 'right',
      sortable: true,
      sortDirection: sort_by === 'ave_30_day_cont' ? sort : '',
      onSortDirection: (
        key: string,
        item: { label: string; value: string },
      ) => {
        const { value } = item
        handleFilterChange('sort', {
          type: 'ave_30_day_cont',
          value,
        })
      },
      custom: (value: any) => {
        return (
          <div className='flex justify-end'>
            <Contribution num={value} />
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Table
        loading={loading}
        current={page}
        resizePageSize={pageSize}
        columns={columns}
        data={data}
        total={total}
        onPageNoChange={value => onPageNoChange?.(value)}
        clickedRow={goDetail}
      />
      <div className='w-full mt-8'>
        <div className='flex justify-between  divide-x divide-solid divide-dark-100 border border-solid border-dark-100 rounded-lg px-10 py-4'>
          <div className='flex flex-col space-y-1 pr-10'>
            <p className='text-light-700 font-medium text-sm'>
              How are titles earned?
            </p>
            <p className='text-light-500 text-xs'>
              Titles are earned with corresponding statistics from the selected
              time period
            </p>
          </div>
          <div className='flex justify-between space-x-10 pl-10'>
            <div className='flex flex-col space-y-1 w-48'>
              <div className='text-light-700 font-medium text-sm'>
                👷 Grinder
              </div>
              <div className='text-light-500 text-xs'>
                Any rep with the average daily effort point above the team
                average
              </div>
            </div>
            <div className='flex flex-col space-y-1 w-48'>
              <div className='text-light-700 font-medium text-sm'>
                🦸 Carrier
              </div>
              <div className='text-light-500 text-xs'>
                The top 2 reps with the most signups
              </div>
            </div>
            <div className='flex flex-col space-y-1 w-48'>
              <div className='text-light-700 font-medium text-sm'>🧙 Mage</div>
              <div className='text-light-500 text-xs'>
                The top 2 reps with the highest signups/effort points ratio
              </div>
            </div>
            <div className='flex flex-col space-y-1 w-48'>
              <div className='text-light-700 font-medium text-sm'>
                🦹 Maverick
              </div>
              <div className='text-light-500 text-xs'>
                Any rep with more than 20% self-gen signups
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
