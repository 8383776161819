import axiosInstance from 'utils/axios'
import { formatParamsFormData } from 'utils/tools'

// signups 相关

// create signups
export async function createSignsupApi(params: Record<string, any>) {
  const response = await axiosInstance.request({
    url: 'accounts',
    method: 'post',
    data: params,
  })
  return response.data
}
// edit signups
export async function editSignsupApi(params: Record<string, any>, id?: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}`,
    method: 'patch',
    data: params,
  })
  return response.data
}
// signup info
export async function getSignupInfoApi(id: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/signup`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

// approve signups
export async function approveSignupsApi(id: string) {
  const response = await axiosInstance.request({
    url: `signups/${id}/approve`,
    method: 'post',
  })
  return response.data
}

// onboarding info =====================================================================
export async function getOnboardingInfoApi(id: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/onboarding`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

// 更新 onboarding
export type TupdateOnboarding = {
  employment_id?: number // ACCOUNT USERS
  person_of_contact?: number // ACCOUNT USERS
  shipping_address?: string
  assignee_id?: string
  attention?: string // edit attention
  status?: 'done' // task COMPLETE ONBOARDING 蓝色按钮
  followup_time?: string
  GBP_preference?: Record<
    'has_gmb_ownership' | 'need_help_regain_gmb' | 'delegate_gmb_update',
    string | number
  >
  website_preference?: Record<
    'website_preference' | 'has_existing_domain' | 'delegate_dns_or_website',
    string | number
  >
}
export async function updateOnboardingApi(id: string, obj: TupdateOnboarding) {
  const response = await axiosInstance.request({
    url: `onboardings/${id}?_method=patch`,
    method: 'post',
    data: formatParamsFormData(obj),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

type TComment = {
  onboarding_id: string
  content: string
  notification: number
}
export async function createCommentApi(params: TComment) {
  const response = await axiosInstance.request({
    url: 'onboarding_comments',
    method: 'post',
    data: params,
  })
  return response.data
}

export async function updateCommentApi(id: string, params: TComment) {
  const response = await axiosInstance.request({
    url: `onboarding_comments/${id}`,
    method: 'patch',
    data: params,
  })
  return response.data
}

export async function deleteCommentApi(id: string) {
  const response = await axiosInstance.request({
    url: `onboarding_comments/${id}`,
    method: 'delete',
  })
  return response.data
}

export async function getAssigneeListApi() {
  const response = await axiosInstance.request({
    url: 'accounts/assignee',
    method: 'get',
  })
  return response.data
}

// logistic item
export async function createLogisticApi(id: string, item: string) {
  const response = await axiosInstance.request({
    url: `onboardings/${id}/onboarding_logistics`,
    method: 'post',
    data: { item },
  })
  return response.data
}

export async function updateLogisticApi(
  id: string,
  logisticId: string,
  easyship_id: string,
) {
  const response = await axiosInstance.request({
    url: `onboardings/${id}/onboarding_logistics/${logisticId}`,
    method: 'patch',
    data: { easyship_id },
  })
  return response.data
}

export async function deleteLogisticApi(id: string, logisticId: string) {
  const response = await axiosInstance.request({
    url: `onboardings/${id}/onboarding_logistics/${logisticId}`,
    method: 'delete',
  })
  return response.data
}

export async function approveLogisticApi(id: string) {
  const response = await axiosInstance.request({
    url: `onboarding_logistics/${id}/approve`,
    method: 'post',
  })
  return response.data
}

export async function syncEasyShipApi(id: string) {
  const response = await axiosInstance.request({
    url: `easyship?easyship_id=${id}`,
    method: 'get',
  })
  return response.data
}

type TTask = {
  description?: string
  type?: string // 'success' | 'onboarding'
  completed?: boolean // update 时需要
}
// task
export async function createTaskApi(id: string, params: TTask) {
  const response = await axiosInstance.request({
    url: `onboardings/${id}/onboarding_tasks`,
    method: 'post',
    data: params,
  })
  return response.data
}

export async function deleteTaskApi(onboardingId: string, id: string) {
  const response = await axiosInstance.request({
    url: `onboardings/${onboardingId}/onboarding_tasks/${id}`,
    method: 'delete',
  })
  return response.data
}

export async function updateTaskApi(
  onboardingId: string,
  id: string,
  params: TTask,
) {
  const response = await axiosInstance.request({
    url: `onboardings/${onboardingId}/onboarding_tasks/${id}`,
    method: 'patch',
    data: params,
  })
  return response.data
}

export async function sortTaskApi(id: string, ids: number[]) {
  const response = await axiosInstance.request({
    url: `onboardings/${id}/onboarding_tasks/sort`,
    method: 'post',
    data: { onboarding_task_ids: ids },
  })
  return response.data
}

export async function chargeNowApi(id: string, chargeFee: number) {
  const response = await axiosInstance.request({
    url: `onboardings/${id}/charge_now`,
    method: 'post',
    data: { charge_fee: chargeFee },
  })
  return response.data
}

// success 相关 =====================================================================
// success info
export async function getSuccessInfoApi(id: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/success`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

// 更新 onboarding
export type TupdateSuccess = {
  GBP_ordering_tracking?: string
  GBP_website_tracking?: string
  employment_id?: number // ACCOUNT USERS
  person_of_contact?: number // ACCOUNT USERS
}
export async function updateSuccessApi(id: string, params: TupdateSuccess) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/success/update`,
    method: 'post',
    data: formatParamsFormData(params),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

// Vault 相关 =====================================================================
export async function getVaultInfoApi(id: string, password: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/vault/onboarding_vaults`,
    params: {
      password,
    },
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

type Tvault = {
  message: string
}
export async function createVaultApi(id: string, params: Tvault) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/vault/onboarding_vaults`,
    data: params,
    method: 'post',
  })
  return response.data
}

export async function updateVaultApi(
  id: string,
  vaultId: string,
  params: Tvault,
) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/vault/onboarding_vaults/${vaultId}`,
    data: params,
    method: 'patch',
  })
  return response.data
}

export async function deleteVaultApi(id: string, vaultId: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/vault/onboarding_vaults/${vaultId}`,
    method: 'delete',
  })
  return response.data
}

// menu 相关 =====================================================================
export async function getMenuInfoApi(id: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/menu`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function syncLinkApi(id: string, url: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/menu/sync?url=${url}`,
    method: 'get',
  })
  return response.data
}

export async function menuExportApi(id: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/menu/export`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

type TupdateMenu = {
  menu_link?: null
  menu_work_notes: string
}
export async function updateMenuApi(id: string, params: TupdateMenu) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/menu/update`,
    method: 'post',
    data: params,
  })
  return response.data
}

export async function uploadMenuFileApi(id: string, file: Blob) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/menu/upload`,
    method: 'post',
    data: formatParamsFormData({ file }),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export async function removeMenuFileApi(id: string, fileId: string) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/menu/google_drive_files/${fileId}`,
    method: 'post',
  })
  return response.data
}

export async function importMenuApi(id: string, info: FormData) {
  const response = await axiosInstance.request({
    url: `accounts/${id}/menu/import`,
    method: 'post',
    data: info,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

// account detail 左边栏 TERMINATE
type Tterminate = {
  reason: string
  comment: string
}
export async function terminateApi(id: string, params: Tterminate) {
  const response = await axiosInstance.request({
    url: `accounts/${id}`,
    method: 'delete',
    data: params,
  })
  return response.data
}
