import { useState, useEffect, useRef } from 'react'
import { SolidCalendar, MarkX } from '@rushable/icons'
import Button from 'components/Button'
import cn from 'classnames'
import SingleInput from 'components/SingleInput'
import useOnClickOutside from 'hooks/useOnClickOutside'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

type TDateRangeProp = {
  className?: string
  onChange: (e: DateItem) => void
}

type DateItem = {
  startDate?: undefined | Date
  endDate?: undefined | Date
  key: string
}

const defaultRange = [
  {
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  },
]

export default function RangePickerComps({
  className,
  onChange,
}: TDateRangeProp): JSX.Element {
  const ref = useRef(null)
  const [dateField, setDateField] = useState('')
  const [open, setOpen] = useState(false)
  const [state, setState] = useState<DateItem[]>(defaultRange)

  const handleChange = (e: any) => {
    setState([e.selection])
  }

  useEffect(() => {
    const date = state[0]
    if (date.startDate && date.endDate) {
      const s = moment(date.startDate).format('YYYY/MM/DD')
      const e = moment(date.endDate).format('YYYY/MM/DD')
      setDateField(`${s} - ${e}`)
    }
  }, [state])

  const handleClickOutside = () => {
    setOpen(false)
  }
  useOnClickOutside(ref, handleClickOutside)

  return (
    <div className={cn('relative', className)} ref={ref}>
      <SingleInput
        name='date_range'
        placeholder='Select'
        autoComplete='off'
        type='text'
        value={dateField || ''}
        iconAfter={<SolidCalendar className='text-light-300' size={16} />}
        onClick={e => {
          e.preventDefault()
          setOpen(true)
        }}
        onChange={() => null}
      />
      {open && (
        <div className='absolute z-20 dateRange-container'>
          <div className='date-range'>
            <DateRange
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              onChange={handleChange}
              ranges={state}
              direction='horizontal'
              maxDate={new Date()}
              rangeColors={['#008DFF']}
            />
            <Button
              className='date-confirm mx-auto'
              color='primary'
              onClick={() => {
                setOpen(false)
                onChange(state[0])
              }}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
