import React, { useState } from 'react'
import { RangePicker } from 'components/DatePicker'
import SingleInput from 'components/SingleInput'
import { Select } from 'components/Select'
import { LinedSearch } from '@rushable/icons'
import { typeOptions } from '../helpers/constant'

export type TBlocklistFilterProp = {
  filterForm: any
  handleFormChange: (type: string, value: string | number) => void
}

export default function BlocklistFilter({
  filterForm,
  handleFormChange,
}: TBlocklistFilterProp): JSX.Element {
  const { type, keyword } = filterForm

  return (
    <div className='flex space-x-4'>
      <Select
        className='w-216'
        value={type}
        onChange={(value, item) => {
          handleFormChange('type', value)
        }}
        options={typeOptions}
      ></Select>
      <RangePicker
        className='w-256'
        onChange={(e: any) => {
          handleFormChange('dates', e)
        }}
      />

      <SingleInput
        name='Keyword'
        className='w-80'
        placeholder='Customer Name or Card Ending'
        iconAfter={<LinedSearch className='text-light-300' size={16} />}
        value={keyword}
        onChange={e => {
          handleFormChange('keyword', e.target.value)
        }}
      />
    </div>
  )
}
