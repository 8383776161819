import { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { SolidCopy } from '@rushable/icons'
import { useAppDispatch } from 'redux/hooks'
import { toast } from 'react-toastify'
import { AdminLayout, AdminPageTitle } from 'components/Layout'
import Button from 'components/Button'
import AlertText from 'components/AlertText'
import CopyableText from 'components/CopyableText'
import AccountSideBar from '../../components/AccountSideBar'
import SignupForm from './SignupForm'
import { getSignupInfoApi, setNeedReload } from 'redux/account'
import { ONBOARDING_BASIC_URL } from 'data/constant'
import SignupFormContext from './context'

type Iprops = {
  type: 'tabs' | 'form' // tabs: signsup 阶段已完成，form: 正在进行 signsup 阶段
  signupId?: string
  accountDetail?: any
}

type Tstatus = 'create' | 'draft' | 'signup' | 'pending'

export default function TabSignup({ type, signupId, accountDetail }: Iprops) {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const [status, setStatus] = useState<Tstatus>()
  const [formDisabled, setFormDisabled] = useState(params.id !== 'create')
  const [loading, setLoading] = useState({ draft: false, signup: false })
  const [formData, setFormData] = useState<any>({})
  const formRef = useRef<any>()

  useEffect(() => {
    setFormDisabled(params.id !== 'create')
    setStatus(params.id === 'create' ? 'create' : undefined)
  }, [params.id])

  useEffect(() => {
    if (signupId) {
      getSignupInfo(signupId)
    }
  }, [signupId])

  const getSignupInfo = async (signupId: string) => {
    try {
      const res = await getSignupInfoApi(signupId)
      const isApprove = getNeedApprove(
        res.signup_form?.signup_form_approvals || [],
      )

      setFormData({
        ...(res.signup_form || {}),
        isApprove,
      })
      if (res.status === 'draft') {
        setStatus('draft')
      }
      if (res.status === 'signup') {
        setStatus('signup')
        if (isApprove) {
          setStatus('pending')
        }
      }
      setFormDisabled(true)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  // 是否全部审核完成
  const getNeedApprove = (approves: any[]) => {
    return !approves.every(item => !!item.approved_at && !!item.approved_by)
  }

  // 保存操作
  const handleSubmit = async (status: 'draft' | 'signup') => {
    setLoading(prev => ({ ...prev, [status]: true }))
    try {
      await formRef.current.handleSubmit(status)
      // 创建或修改成功后，返回列表需要忽略缓存加载
      dispatch(setNeedReload(true))
      // 编辑情况下
      if (signupId) {
        getSignupInfo(signupId)
      } else {
        // 新增情况下
        navigate(-1)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(prev => ({ ...prev, [status]: false }))
  }

  const renderOptButton = (status: Tstatus) => {
    const draft = (
      <Button
        loading={status === 'draft' ? loading.draft : loading.signup}
        color='secondary'
        onClick={() => {
          let act: any = 'draft'
          if (status && status !== 'draft' && params.id !== 'create') {
            act = 'signup'
          }
          handleSubmit(act)
        }}
      >
        {params.id === 'create' ? 'SAVE DRAFT' : 'SAVE CHANGE'}
      </Button>
    )
    const signup = (
      <Button
        loading={loading.signup}
        className='ml-4'
        onClick={() => handleSubmit('signup')}
      >
        CREATE SIGNUP
      </Button>
    )
    const edit = (
      // 进入 hello 流程的不可被编辑
      <>
        {!formData.current_step && (
          <Button
            color='secondary'
            theme='outlined'
            onClick={() => setFormDisabled(false)}
          >
            EDIT
          </Button>
        )}
      </>
    )

    // 右上角操作按钮
    const optBtnMap = {
      create: (
        <>
          {draft} {signup}
        </>
      ),
      draft: (
        <>
          {formDisabled ? edit : draft}
          {signup}
        </>
      ),

      signup: (
        <>
          {formDisabled ? (
            <>
              {edit}
              <CopyableText text={ONBOARDING_BASIC_URL + formData.src}>
                <Button
                  className='ml-4'
                  theme='outlined'
                  color='secondary'
                  iconAfter={<SolidCopy size={12} />}
                >
                  COPY SIGNUP LINK
                </Button>
              </CopyableText>
            </>
          ) : (
            draft
          )}
        </>
      ),
      pending: (
        <>
          {formDisabled ? (
            <>
              {edit}
              <AlertText
                className='ml-4'
                color='yellow'
                text='Approval Required'
              />
            </>
          ) : (
            draft
          )}
        </>
      ),
    }
    return optBtnMap[status]
  }
  return (
    <SignupFormContext.Provider
      value={{
        data: formData,
        // approve 完成之后刷新接口
        handleInit: () => getSignupInfo(signupId!),
      }}
    >
      {type === 'form' ? (
        <AdminLayout showNavs={false}>
          <AdminPageTitle
            title='ACCOUNT DETAIL'
            right={renderOptButton(status!)}
          />
          <div className='flex'>
            <AccountSideBar type='Signup' data={accountDetail} />
            <div className='flex-1 mt-6 mb-10 px-10'>
              <SignupForm
                ref={formRef}
                editId={signupId}
                signupForm={formData}
                formDisabled={formDisabled}
              />
            </div>
          </div>
        </AdminLayout>
      ) : (
        // 当 signup 阶段完成时展示该组件
        <SignupForm
          editId={signupId}
          formDisabled={true}
          signupForm={formData}
        />
      )}
    </SignupFormContext.Provider>
  )
}
