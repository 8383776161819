import { useState } from 'react'
import { SolidFile } from '@rushable/icons'
import moment from 'moment'
import currency from 'currency.js'

export type TSubscriptionTooltipProps = {
  data?: any
}

export default function SubscriptionTooltip({
  data,
}: TSubscriptionTooltipProps) {
  const [showSubscription, setShowSubscription] = useState(false)

  const currStart = moment(data?.current_cycle?.current_period_start).format(
    'YYYY/MM/DD',
  )
  const currEnd = moment(data?.current_cycle?.current_period_end).format(
    'YYYY/MM/DD',
  )

  const nextStart = moment(data?.next_cycle?.start_at).format('YYYY/MM/DD')
  const nextEnd = moment(data?.next_cycle?.end_at).format('YYYY/MM/DD')

  const transformData = (items: any[] = []) => {
    const subTotal = items.reduce((acc: number, cur: any) => {
      const curAmount = currency(cur.origin_amount).multiply(cur.quantity || 0)
      return currency(acc).add(curAmount)
    }, 0)

    const total = items.reduce((acc: number, cur: any) => {
      const curAmount = currency(cur.unit_amount).multiply(cur.quantity || 0)
      return currency(acc).add(curAmount)
    }, 0)

    const discount = currency(total).subtract(subTotal)
    return {
      subTotal,
      total,
      discount,
    }
  }

  const renderItems = (type: 'curr' | 'next') => {
    const key = type === 'curr' ? 'current_cycle' : 'next_cycle'
    const items = data[key]?.items || []
    const { subTotal, total, discount } = transformData(items)

    return (
      <>
        <div className='flex flex-col space-y-1 mt-4'>
          <div className='flex h-6 text-light-300'>
            <div className='w-48 flex items-center font-bold'>ITEMS</div>
            <div className='w-10 flex items-center justify-end font-bold'>
              QTY
            </div>
            <div className='w-20 flex items-center justify-end font-bold'>
              PRICE
            </div>
            <div className='w-20 flex items-center justify-end font-bold'>
              AMOUNT
            </div>
          </div>
          {items.map((item: any, index: number) => {
            return (
              <div className='flex h-6 text-light-700' key={index}>
                <div className='w-48 flex items-center truncate'>
                  {item.name}
                </div>
                <div className='w-10 flex items-center justify-end'>
                  {item.quantity || 0}
                </div>
                <div className='w-20 flex items-center justify-end'>
                  {currency(item.origin_amount).format()}
                </div>
                <div className='w-20 flex items-center justify-end'>
                  {currency(item.quantity)
                    .multiply(item.origin_amount || 0)
                    .format()}
                </div>
              </div>
            )
          })}
        </div>
        <div className='my-[6px] border border-dashed border-dark-100'></div>
        <div className='flex flex-col space-y-1'>
          <div className='flex h-6 '>
            <div className='w-48'></div>
            <div className='w-10'></div>
            <div className='text-light-300 w-20 flex items-center justify-end'>
              Subtotal
            </div>
            <div className='text-light-700 w-20 flex items-center justify-end'>
              {currency(subTotal).format()}
            </div>
          </div>
          <div className='flex h-6 text-light-700'>
            <div className='w-48'></div>
            <div className='w-10'></div>
            <div className='text-light-300 w-20 flex items-center justify-end'>
              Discount
            </div>
            <div className='text-light-700 w-20 flex items-center justify-end'>
              {currency(discount).format()}
            </div>
          </div>
          <div className='flex h-6 text-light-700'>
            <div className='w-48'></div>
            <div className='w-10'></div>
            <div className='text-light-300 w-20 flex items-center justify-end'>
              Total
            </div>
            <div className='text-light-700 w-20 flex items-center justify-end'>
              {currency(total).format()}
            </div>
          </div>
        </div>
      </>
    )
  }

  const { total } = transformData(data.current_cycle?.items || [])
  return (
    <div
      onMouseEnter={() => setShowSubscription(true)}
      onMouseLeave={() => setShowSubscription(false)}
      className='relative '
    >
      <div className='flex items-center text-sm text-light-500'>
        {data.current_cycle?.items?.length || 0} Item •{' '}
        {currency(total || 0).format()}/mo
        <SolidFile className='ml-1 text-light-100' size={16} />
      </div>
      {showSubscription && (
        <div className='absolute top-0 left-full z-10 flex rounded-2xl bg-dark-500 border border-solid border-dark-100 shadow-[0_24px_24px_0px_rgba(0, 0, 0, 0.24)] divide-x divide-solid divide-dark-100'>
          <div className='flex flex-col pl-6 pr-10 py-6'>
            <div className='flex space-x-4 text-light-300 text-sm'>
              <div className='flex-1'>Current Billing Cycle</div>
              <div className='flex-1 text-right'>
                {currStart} - {currEnd}
              </div>
            </div>
            {renderItems('curr')}
          </div>
          <div className='flex flex-col pl-10 pr-6 py-6'>
            <div className='flex space-x-4 text-light-300 text-sm'>
              <div className='flex-1'>Next Billing Cycle</div>
              <div className='flex-1 text-right'>
                {nextStart} - {nextEnd}
              </div>
            </div>
            {renderItems('next')}
          </div>
        </div>
      )}
    </div>
  )
}
