import type { TBonus } from '../helpers/constant'
import currency from 'currency.js'

export type TBonusCalculationSideBarProp = {
  className?: string
  bonus: TBonus
}

export default function BonusCalculationSideBar({
  className = '',
  bonus,
}: TBonusCalculationSideBarProp): JSX.Element {
  const {
    volume_amount,
    self_gen_quantity,
    self_gen_bonus,
    cancellation_quantity,
    adjustment_amount,
    setup_fee_bonus,
    total_bonus,
    launch_bonus,
    setup_fee_amount,
    effort,
    effort_bonus,
  } = bonus
  return (
    <div
      className='w-400 border-r border-dark-100 text-light-300 text-xs'
      style={{ minHeight: 'calc(100vh - 72px)' }}
    >
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Effort Bonus</div>
          <div className='text-light-500'>
            {currency(effort, {
              symbol: '',
              separator: '',
              precision: 0,
            }).format()}{' '}
            pts x 10%
          </div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          {currency(effort_bonus, { separator: '', precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Launch Bonus</div>
          <div className='text-light-500'>
            {currency(volume_amount, { separator: '', precision: 0 }).format()}{' '}
            x 15%
          </div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          {currency(launch_bonus, { separator: '', precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Self-gen Bonus</div>
          <div className='text-light-500'>$30/ea x {self_gen_quantity}</div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          {currency(self_gen_bonus, { separator: '', precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Setup Fee Bonus</div>
          <div className='text-light-500'>
            {currency(setup_fee_amount, {
              separator: '',
              precision: 0,
            }).format()}{' '}
            x 20%
          </div>
          {/* <TierBonus
            tierFeeList={{
              0: 0,
              4: 100,
              8: 200,
              12: 400,
              16: 600,
              20: 800,
              24: 1200,
              28: 1600,
              32: 2000,
              36: 2400,
            }}
            current={4}
          /> */}
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          {currency(setup_fee_bonus, { separator: '', precision: 0 }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center border-b border-dark-100'>
        <div className='flex flex-col space-y-2'>
          <div className='font-bold'>Adjustment</div>
          <div className='text-light-500'>
            Early cancellation x {cancellation_quantity}
          </div>
        </div>
        <div className='text-right font-bold text-lg text-light-700'>
          -{' '}
          {currency(adjustment_amount, {
            separator: '',
            precision: 0,
          }).format()}
        </div>
      </div>
      <div className='py-4 px-10 flex justify-between items-center'>
        <div className='font-bold'>Total Bonus</div>
        <div className='text-right font-bold text-lg text-green'>
          {currency(total_bonus, { separator: '', precision: 0 }).format()}
        </div>
      </div>
    </div>
  )
}
